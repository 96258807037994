import { Question, Location, Answer } from "./types";

export const createTooltipString = (questions: Array<Question>) => {
  let markerString = "<center>";

  // Add question answers to tooltip:
  questions.forEach((q: Question) => {
    if (q.isExtraCredit) {
      markerString += `<i>${q.correctAnswer}</i><br/>`
    }
    else {
      markerString += `${q.correctAnswer}<br/>`
    }
  });

  markerString += "</center>";

  return markerString

};


export const createTooltipAnswerString = (
  answers: {[questionId: string]: string}, 
  questions: Array<Question>
) => {
  let markerString = "<center>";

  // Add question answers to tooltip:
  questions.forEach((q: Question) => {
    if (answers[q.id]) {
      if (q.isExtraCredit) {
        markerString += `<b>Extra credit:</b> <i>${answers[q.id]}</i><br/>`
      }
      else {
        markerString += `${answers[q.id]}<br/>`
      }
    }
  });

  markerString += "</center>";

  return markerString

};

// Takes an array of questions and answers
// and returns an array of numRequiredQuestions and numRequiredAnswers
export const getScore = (
  locations: Array<Location>, 
  answers: Array<Answer>
) => {
  let numRequiredQuestions = 0;
  let numRequiredAnswers = 0;
  locations.forEach((loc: Location) => {
    loc.questions.forEach(q => {
      if (!q.isExtraCredit) {
        numRequiredQuestions++;
        if (answers.find(a => a.questionId === q.id)) {
          numRequiredAnswers++;
        }
      }
    });
  });

  const numCorrect = answers.filter(a => a.isCorrect).length;

  return { numRequiredQuestions, numRequiredAnswers, numCorrect };
}