import { useRef, useState, useEffect } from 'react';
import { post } from 'superagent';
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import { Modal, Button } from 'react-bootstrap';
import { Link, useParams } from "react-router-dom";
import Draggable from "react-draggable";
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import { Test, Question, Location } from "../types";
import { createTooltipAnswerString } from "../helpers";

interface MapTestProps {
  getTests: () => void,
  tests: Array<Test>,
  isReady: boolean
};

interface UserAnswer {
  [questionId: string]: string
}

const L = require('leaflet');
const southwest = L.latLng(-90, -180);
let labelsGroup: any;
const iconOffset = [13.5, 41];

let DefaultIcon = L.icon({
  iconUrl: "/marker-blank.png",
  shadowUrl: iconShadow,
  iconAnchor: iconOffset
});

let SelectedIcon = L.icon({
  iconUrl: "/marker-selected.png",
  shadowUrl: iconShadow,
  iconAnchor: iconOffset
});

let AnsweredIcon = L.icon({
  iconUrl: "/marker-answered.png",
  iconAnchor: iconOffset
});

// Displays labels that user has added onto map
const MapLabels = ({locations, answers, handleClickMarker, selectedLocationId}: any) => {

  const map: any = useMap();
  
  if (labelsGroup) {
    labelsGroup.eachLayer((l: any) => {
      map.removeLayer(l);
    })
  }

  labelsGroup = L.layerGroup().addTo(map);

  locations.forEach((loc: any) => {
    // Find user answer for this question
    const numRequiredQuestions = loc.questions.filter((q: Question) => !q.isExtraCredit).length;
    const numAnsweredQuestions = loc.questions.filter((q: Question) => !q.isExtraCredit && answers[q.id]).length;
    const isAnswered = numRequiredQuestions === numAnsweredQuestions;
    const tooltipString = createTooltipAnswerString(answers, loc.questions);
    const pos = loc.coordinates.split(",");
    const isSelected = selectedLocationId && selectedLocationId === loc.id;
    let color = isAnswered ? '#008fd5' : '#FFFFFF';

    if (loc.type === "point") {
      let icon = DefaultIcon;
  
      if (isAnswered) {
        icon = AnsweredIcon;
      }
  
      if (isSelected) {
        icon = SelectedIcon;
      }
  
      const marker = new L.marker(pos, {icon, questionId: loc.id}).on('click', handleClickMarker);
  
      if (isAnswered) {
        marker.bindTooltip(tooltipString, {direction: 'top', offset: [0, -41], permanent: true});
      }
  
      labelsGroup.addLayer(marker);
    }

    // If river, make line
    else if (loc.type === "line") {
      const polygonCoords = [];

      while (pos.length) polygonCoords.push(pos.splice(0,2));
      const line = L.polyline(polygonCoords, {color, questionId: loc.id, weight: 6}).on('click', handleClickMarker);

      if (isSelected) {
        color = '#FFF200';
      }

      if (isAnswered) {
        line.bindTooltip(tooltipString, {direction: 'top', permanent: true});
      }

      labelsGroup.addLayer(line);
    }

    // Length > 2 = an area
    else {
      const polygonCoords = [];

      if (isSelected) {
        color = '#FFF200';
      }

      while (pos.length) polygonCoords.push(pos.splice(0,2));
      const polygon = L.polygon(polygonCoords, {color, questionId: loc.id}).on('click', handleClickMarker);
      
      if (isAnswered) {
        polygon.bindTooltip(tooltipString, {direction: 'top', permanent: true});
      }

      labelsGroup.addLayer(polygon);

    }

  })

  labelsGroup.addTo(map);

  return null;
}

function MapTest({ getTests, tests, isReady }: MapTestProps) {
  
  let { testId }: any = useParams();
  let test = tests.find(t => t.id === parseInt(testId));
  const mapRef: any = useRef();
  const popupRef: any = useRef();
  const prevSelectedLocationId: any = useRef();
  const [answers, setAnswers] = useState<UserAnswer>({});
  const [selectedLocationId, setSelectedLocationId] = useState<string|null>(null);
  const [showIncompleteModal, setShowIncompleteModal] = useState<boolean>(false);
  const [showLeavePageModal, setShowLeavePageModal] = useState<boolean>(false);
  const [showConfirmSubmitModal, setShowConfirmSubmitModal] = useState<boolean>(false);
  const [error, setError] = useState<string|null>(null);
  const [popupPos, setPopupPos] = useState<{x: number, y: number}>({x: 0, y: 0});

  const submitAnswers = async () => {
    await post(`${process.env.REACT_APP_API_URL}/api/results/${testId}`)
    .send(answers)
    .withCredentials()
    .then(() => { getTests(); })
    .catch((err: any) => {
      if (err.response) {
        setError(err.response.body.message)
      }
    });

    setShowConfirmSubmitModal(false);
  }

  // Opens an input to allow naming the marker that was clicked
  const handleClickMarker = (e: any) => {
    const locationId = e.target.options.questionId;
    setSelectedLocationId(locationId);
  }

  const setPopupPosition = () => {

    if (popupRef.current) {
      popupRef.current.classList.remove("fade-transition");

      const center = getMapCenter();
      setPopupPos({x: center.x, y: center.y});

        popupRef.current.classList.add("hidden");

        setTimeout(() => {
          popupRef.current.classList.remove("hidden");
          if (prevSelectedLocationId.current !== null) {
            popupRef.current.classList.add("fade-transition");
          }
          setPopupPos({x: center.x, y: center.y - 10});
        }, 200);
        
    }
  }

  const handleAddAnswer = (answerText: string, questionId: number|string) => {
    let answersCopy = Object.assign({}, answers);
    answersCopy[questionId] = answerText;
    setAnswers(answersCopy);
  }

  const getMapCenter = () => {
    let centerCoords = {x: 0, y: 0};
    const _offsetEl = document.getElementsByTagName("body")[0];
    const leafletContainer = mapRef.current.getElementsByClassName("leaflet-container")[0];

    if (leafletContainer) {
      const pos = leafletContainer.getBoundingClientRect();
      const bodyWidth = _offsetEl.offsetWidth;
      const popupWidth = popupRef.current.getBoundingClientRect().width;
      centerCoords.x =  (bodyWidth / 2) - (popupWidth / 2);
      centerCoords.y = (pos.height/2) - pos.y;
    }

    return centerCoords;
  }

  useEffect(() => {
    setPopupPosition();
    prevSelectedLocationId.current = selectedLocationId;
  }, [selectedLocationId]);

  if (isReady) {
    if (test && test.results.length > 0) {    
      return (
        <div>
          <Link to="/"><i className="bi bi-arrow-left"/> Back to all tests</Link>
          <br/><br/>
          <p>This test has been submitted.</p>
        </div>
      );
    }

    else if (test) {

      let coordsArray = test.mapCoordinates.split(",");
      let selectedLocation: Location|undefined = selectedLocationId ? test.locations.find(l => l.id == selectedLocationId) : undefined;
      let startCoords: [number, number] = [parseInt(coordsArray[0]), parseInt(coordsArray[1])];
      let numRequiredQuestions = 0;
      let numRequiredAnswers = 0;

      // Count total questions
      test.locations.forEach((loc: Location) => {
        loc.questions.forEach((q: Question) => {
          if (!q.isExtraCredit) {
            if (answers[q.id]) {
              numRequiredAnswers++;
            }
            numRequiredQuestions++;
          }
        })
      });

      let allQuestionsAnswered = numRequiredQuestions === numRequiredAnswers;

      const handleSubmit = () => {
        if (allQuestionsAnswered) {
          // Show confirm submit dialog
          setShowConfirmSubmitModal(true)
        }
        else {
          setShowIncompleteModal(true);
        }
      }

      return (
        <div>
          <div className="back-btn">

            {numRequiredAnswers === 0 ? 
              <Link to="/"><i className="bi bi-arrow-left"/> Back to all tests</Link> : 
              <a href="#" onClick={() => setShowLeavePageModal(true)}>
                <i className="bi bi-arrow-left"/> Back to all tests
              </a>
            }
          </div> 

          <p><b>Instructions:</b> Click on a pointer on the map to name the location. When you're done, click "Submit" to end the test.</p>

          <div className="submit-container">
            <button 
              className={`btn btn-success ${allQuestionsAnswered ? "" : "visually-disabled"}`}
              onClick={(e) => handleSubmit()}>
              Submit Answers
            </button>
            {allQuestionsAnswered ? 
              <div className="text-success complete">All locations labeled!</div> : 
              <div>
                {`${numRequiredAnswers}/${numRequiredQuestions} Locations labeled`}
              </div>
            }
          </div>

          <div className="quiz-container">

            <div className="map student-map" ref={mapRef}>

              <MapContainer 
                center={startCoords} 
                zoom={5}
                minZoom={3} 
                maxBounds={L.latLngBounds(southwest, null)}>
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}"
                />

              <MapLabels 
                locations={test.locations} 
                answers={answers} 
                selectedLocationId={selectedLocationId}
                handleClickMarker={handleClickMarker}/>
            
              </MapContainer>
            </div>

          </div>

          <Modal show={showIncompleteModal} onHide={() => setShowIncompleteModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Not Complete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>There are still some locations that have not been labeled.</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowIncompleteModal(false)}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showLeavePageModal} onHide={() => setShowLeavePageModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>You have unsubmitted answers</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Leaving this page will reset your answers. Are you sure you want to leave?</p>
            </Modal.Body>
            <Modal.Footer>
              <Link to="/">
                <Button variant="secondary">
                  Leave
                </Button>
              </Link>
              <Button variant="primary" onClick={() => setShowLeavePageModal(false)}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showConfirmSubmitModal} onHide={() => setShowConfirmSubmitModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm submission</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to submit your answers? They cannot be changed once submitted.</p>
            </Modal.Body>
            <Modal.Footer>
              <Link to="/">
                <Button variant="secondary" onClick={() => setShowConfirmSubmitModal(false)}>
                  Cancel
                </Button>
              </Link>
              <Button variant="primary" onClick={() => submitAnswers()}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          <Draggable 
            handle=".handle"
            nodeRef={popupRef}
            position={popupPos.x && popupPos.y ? popupPos : undefined}
            offsetParent={document.getElementsByTagName("body")[0]}
            onStop={(d, pos) => {
              setPopupPos({x: pos.lastX, y: pos.lastY})
            }}>
            <div 
              className="answer-popup" 
              ref={popupRef} 
              style={{display: selectedLocation ? "inherit" : "none"}}>

              <div className="handle">
                <i className="bi bi-grip-vertical"/>
                <i className="close bi bi-x-lg" onClick={() => {
                  setSelectedLocationId(null);
                }}></i>
              </div>

              <div className="label-form">
                <form>

                  {selectedLocation ? selectedLocation.questions.map((q: Question) => {
                    return (
                      <div className="form-group" key={q.id}>
                        <label>{q.text}</label>
                        <input 
                          className="form-control" 
                          onChange={e => handleAddAnswer(e.target.value, q.id)}
                          value={answers[q.id]}
                        />
                        <br/>
                      </div>
                    )
                  }): null}

                  <button 
                    type="submit" 
                    className="btn btn-primary"
                    onClick={(e) => { e.preventDefault(); setSelectedLocationId(null)}}>
                      Done
                    </button>
                </form>

              </div>       
            </div>  
          </Draggable>

        </div>
      );
    }
    else {
      return <div>Error retrieving test</div>
    }
  }
  
  else {
    return null;
  }
};

export default MapTest;
