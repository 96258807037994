import { useEffect, useState } from 'react';
import { get } from 'superagent';
import { MapContainer, TileLayer, useMap, Marker, Popup, Tooltip, useMapEvents } from 'react-leaflet'
import { useParams } from "react-router-dom";
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { Question, Location } from "../types";
import { createTooltipString } from "../helpers";

const L = require('leaflet')
let labelsGroup: any;

interface MapPreviewProps {
  mapCoordinates: string;
}

const southwest = L.latLng(-90, -180);

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

const MapLabels = ({locations, isShowingLabels}: any) => {

  const map: any = useMap();
  
  if (labelsGroup) {
    labelsGroup.eachLayer((l: any) => {
      map.removeLayer(l);
    })
  }

  labelsGroup = L.layerGroup().addTo(map);

  locations.forEach((loc: any) => {
    const coordsArray = loc.coordinates.split(",");
    let markerString = createTooltipString(loc.questions);

    // Length 2 = a location point
    if (loc.type === "point") {
      const marker = new L.marker(coordsArray, {icon: DefaultIcon});
      marker.bindTooltip(markerString, {direction: 'top', offset: [13, 0], permanent: isShowingLabels});
      labelsGroup.addLayer(marker);
    }

    else if (loc.type === "line") {
      const polygonCoords = [];
      while (coordsArray.length) polygonCoords.push(coordsArray.splice(0,2));
      const line = L.polyline(polygonCoords, {color: '#2B82CB', weight: 6});
      line.bindTooltip(markerString, {direction: 'top', permanent: isShowingLabels});
      labelsGroup.addLayer(line);
    }

    // Length > 2 = an area
    else {
      const polygonCoords = [];
      while (coordsArray.length) polygonCoords.push(coordsArray.splice(0,2));
      const polygon = new L.polygon(polygonCoords, {color: '#2B82CB'});
      polygon.bindTooltip(markerString, {direction: 'top', permanent: isShowingLabels});
      labelsGroup.addLayer(polygon);
    }

  })

  labelsGroup.addTo(map);

  return null;
}

function MapPreview({ mapCoordinates }: MapPreviewProps) {
  
  let coordsArray = mapCoordinates.split(",");

  let startCoords: [number, number] = [parseInt(coordsArray[0]), parseInt(coordsArray[1])];
  let { testId }: any = useParams();
  const [locations, setLocations] = useState<Array<any>>([]);
  const [isShowingLabels, setIsShowingLabels] = useState<boolean>(true);
  const [error, setError] = useState<string|null>(null);

  const getQuestions = async () => {
    // Validate LTI
    await get(`${process.env.REACT_APP_API_URL}/api/location/?testId=${testId}`)
    .withCredentials()
    .then((res: any) => {
      setLocations(res.body)
      return true;
    })
    .catch((err: any) => {
      if (err.response) {
        setError(err.response.body.message)
      }
    });
  }

  const handleLabelToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsShowingLabels(e.target.checked);
  }

  useEffect(() => {
    getQuestions();
  }, []);

  useEffect(() => {
  }, [isShowingLabels]);

  useEffect(() => {
    
  }, [isShowingLabels]);

  return (
    <div id='map-preview'>
      <div className="form-check form-switch">
        <input 
          className="form-check-input" 
          type="checkbox" 
          role="switch" 
          checked={isShowingLabels}
          id="flexSwitchCheckDefault"
          onChange={handleLabelToggle}
        />
        <label className="form-check-label">Show all labels</label>
      </div>
      <div className="instructor-map">
        <MapContainer 
          center={startCoords} 
          zoom={5}
          minZoom={3} 
          maxBounds={L.latLngBounds(southwest, null)}
          >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}"
          />

        <MapLabels locations={locations} isShowingLabels={isShowingLabels}/>
      
        </MapContainer>
      </div>
    </div>
  );
};

export default MapPreview;
