import { useEffect, useState } from 'react';
import { get, del, patch } from 'superagent';
import { useParams } from "react-router-dom";
import { Modal, Button, Toast } from 'react-bootstrap';

import { UserTest, Location, Question } from "../types";
import { getScore } from "../helpers";

interface ResultProps {
  locations: Array<Location>;
}

function Results({locations}: ResultProps) {
  let { testId }: any = useParams();

  const [results, setResults] = useState<Array<UserTest>>([]);
  const [selectedResultId, setSelectedResultId] = useState<number|null>(null);
  const [showEditFeedback, setShowEditFeedback] = useState<boolean>(false);
  const [showDeleteResultModal, setShowDeleteResultModal] = useState<boolean>(false);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState<boolean>(false);
  const [isAddingScore, setIsAddingScore] = useState<boolean>(false);
  const [error, setError] = useState<string|null>(null);

  const getResults = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/results/${testId}`)
    .withCredentials()
    .then((res: any) => {
      setResults(res.body)
      return true;
    })
    .catch((err: any) => {
      if (err.response) {
        setError(err.response.body.message)
      }
    });
  }

  const deleteResult = async () => {
    await del(`${process.env.REACT_APP_API_URL}/api/results/${selectedResultId}`)
    .withCredentials()
    .then((res: any) => {
      // Update results
      getResults();
      setShowDeleteSuccess(true);
    })
    .catch((err: any) => {
      if (err.response) {
        setError(err.response.body.message)
      }
    });
    setShowDeleteResultModal(false)
  }

  // Sends api call to update the answer and mark as correct
  // const markCorrect = async (isCorrect: boolean, answerId: number) => {
  //   await post(`${process.env.REACT_APP_API_URL}/api/answer/mark-correct/${answerId}`)
  //   .withCredentials()
  //   .send({isCorrect})
  //   .then((res: any) => {
  //     // Update results
  //     getResults();
  //   })
  //   .catch((err: any) => {
  //     if (err.response) {
  //       setError(err.response.body.message)
  //     }
  //   });
  // }

  const updateResult = async (
      resultId: number,
      score: string|null, 
      feedback: string|null, 
      isReleased: boolean|null
    ) => {
    await patch(`${process.env.REACT_APP_API_URL}/api/results/${resultId}`)
    .withCredentials()
    .send({score, feedback, isReleased})
    .then((res: any) => {
      // Update results
      getResults();
    })
    .catch((err: any) => {
      if (err.response) {
        setError(err.response.body.message)
      }
    });
  }

  const saveScore = async (resultId: number, e: any) => {
    const scoreText = e.target.parentElement.querySelectorAll("input")[0].value;
    await updateResult(resultId, scoreText, null, null);
    setIsAddingScore(false);
    setSelectedResultId(null);
  }

  const releaseResult = async (resultId: number, isReleased: boolean) => {
    await updateResult(resultId, null, null, isReleased);
    setSelectedResultId(null);
  };

  const saveFeedback = async (resultId: number, e: any) => {
    const feedbackText = e.target.parentElement.querySelectorAll("textarea")[0].value;
    await updateResult(resultId, null, feedbackText, null);
    setSelectedResultId(null);
    setShowEditFeedback(false);
  }

  useEffect(() => {
    getResults();
  }, []);

  // Reset selected result ID back to null when confirm modal closes
  useEffect(() => {
    if (!showDeleteResultModal) {
      setSelectedResultId(null);
    }
  }, [showDeleteResultModal])


  return (
    <div style={{marginTop: "40px"}}>
      <h2>Student Answers</h2>
      <div className="accordion" id="accordionExample">

        {results.length === 0 ? <p>There are no submissions yet.</p> : null}
        
        {results.map((result: UserTest, i) => {
          const formattedNameFirst = result.firstName.charAt(0).toUpperCase() + result.firstName.slice(1);
          const formattedNameLast = result.lastName.charAt(0).toUpperCase() + result.lastName.slice(1);
          let { numRequiredQuestions, numRequiredAnswers, numCorrect } = getScore(locations, result.answers);
          const completed = numRequiredAnswers === numRequiredQuestions;

          return (
            <div className="accordion-item" key={result.id}>
              <h2 className="accordion-header">
                <button 
                  className="accordion-button" 
                  type="button" 
                  data-bs-toggle="collapse" 
                  data-bs-target={`#collapse-${i}`} 
                  aria-expanded="false" 
                  aria-controls={`collapse-${i}`}>
                    <div className="results-header">
                      <div className="d-flex justify-content-start">
                        <span style={{marginRight: "10px"}}>{formattedNameLast}, {formattedNameFirst}</span>
                        {result.score ? <span><b>Score:&nbsp;</b>{result.score}</span> : null}
                      </div>

                      <div className="result-status-container">
                      {result.isReleased ? 
                        <div className="status-msg released">Score released</div> 
                        : null
                      } 

                      {completed ? 
                        <div className="status-msg complete">Completed <i className="bi bi-check-circle"/></div> : 
                        <div className="status-msg incomplete">Incomplete <i className="bi bi-x-circle"/></div>
                      }
                      </div>
                    </div>
                </button>
              

              </h2>
              <div id={`collapse-${i}`} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div className="accordion-body">

                {isAddingScore ? 
                  <div className="edit-score-container d-flex justify-content-start">
                    <b>Score:</b> &nbsp;<input 
                      className="form-control" 
                      type="text"
                      style={{maxWidth: "100px", height: "32px"}}
                      defaultValue={result.score}/>
                    <button 
                      className="btn btn-primary btn-sm"
                      onClick={(e) => saveScore(result.id, e)}>
                      Save
                    </button>
                    <button 
                      onClick={(e) => {
                        setIsAddingScore(false);
                        setSelectedResultId(null);
                      }} 
                      className="btn btn-secondary btn-sm">
                      Cancel
                    </button>
                  </div>
                : <p>
                    <b>Score: </b> {result.score ? result.score : "none"}&nbsp;
                    <i 
                      onClick={(e) => { 
                        setIsAddingScore(true); 
                        setSelectedResultId(result.id)}
                      } 
                      className="bi bi-pencil-square"/>
                  </p>
                }

                <p><b>Feedback: </b>
                  {!showEditFeedback ? 
                    <span>
                      {result.feedback ? result.feedback : "none"}&nbsp;
                      <i onClick={(e) => {
                          setShowEditFeedback(true);
                          setSelectedResultId(result.id);
                        }} className="bi bi-pencil-square"/>
                    </span> 
                    
                  : null}
                </p>

                {selectedResultId === result.id && showEditFeedback ?
                  <div className="feedback-container">
                    <textarea className="form-control" defaultValue={result.feedback}/>
                    <br/>
                    <button 
                      className="btn btn-sm btn-primary" style={{marginRight: "20px"}}
                      onClick={(e) => saveFeedback(result.id, e)}>
                      Save
                    </button>
                    <button 
                      className="btn btn-sm btn-secondary" style={{marginRight: "20px"}}
                      onClick={() => {
                        setSelectedResultId(null);
                        setShowEditFeedback(false);
                      }}>
                      Cancel
                    </button>
                    <br/><br/>
                  </div> : null
                }

                  <button 
                    className={`btn btn-sm ${result.isReleased ? "btn-secondary" : "btn-primary"}`} 
                    onClick={() => {
                      releaseResult(result.id, !result.isReleased)
                    }}>
                    {result.isReleased ? "Hide result from student" : "Release results to student"}
                    </button>
                  <br/><br/>

                  {result.isReleased ? 
                    <p>This result has been released: score and feedback are currently visible to the student.</p> : 
                    <p>This result has not been released. The student cannot see their score or feedback.</p> 
                  }

                  <p><b>Total locations</b>: {numRequiredQuestions}</p>

                  {completed ? null : <p>This student has not submitted all answers.</p> }
                  <table className="results-table table table-hover table-striped table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Correct Answer</th>
                        <th scope="col">Student Answer</th>
                        <th scope="col">Extra Credit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {locations.map((location: Location) => {
                        return location.questions.map((question: Question) => {
                          const answer = result.answers.find(a => a.questionId === question.id);
                          if (answer) {
                            return (
                              <tr key={question.id}>
                                <td>{question.correctAnswer}</td>
                                <td>"{answer.text}"</td>

                                <td style={{textAlign: "center"}}>
                                {question.isExtraCredit ? <i className="bi bi-plus"/> : null}
                                </td>
 
                              </tr>
                            );
                          }
                          else {
                            return (
                              <tr key={question.id}>
                                <td>{question.correctAnswer}</td>
                                <td></td>
                                <td></td>
                              </tr> 
                            )
                          }
                        })
                      })}
                    </tbody>
                  </table>

                  <div className="clearfix">
                    <button 
                      className="btn btn-danger" 
                      style={{float: "right"}}
                      onClick={() => {
                        setSelectedResultId(result.id);
                        setShowDeleteResultModal(true);
                      }}>
                        Delete Results
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    

      <Modal show={showDeleteResultModal} onHide={() => setShowDeleteResultModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Answers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Deleting this student's answers will enable them to re-take the test.
            Are you sure you want to proceed? </p>
          <p><b className="text-danger">This action cannot be undone.</b></p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteResultModal(false)}>
            Cancel
          </Button>
          <Button 
            className="btn btn-danger" 
            variant="primary" 
            onClick={() => deleteResult()}>
            Delete Answers
          </Button>
        </Modal.Footer>
      </Modal>

      <Toast 
        bg="success" 
        onClose={() => setShowDeleteSuccess(false)} 
        show={showDeleteSuccess} 
        delay={3000} 
        autohide>
        <Toast.Body>Result has been deleted</Toast.Body>
      </Toast>

    </div>
  );
};

export default Results;
