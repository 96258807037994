import { Routes, Route } from "react-router-dom";

import InstructorTestList from "./test-list";
import TestPage from "./test-page";

function InstructorView() {

  return (
    <div>
      <Routes>
        <Route path="/test/:testId" element={<TestPage />} />
        <Route path="/" element={<InstructorTestList />} />
      </Routes>
    </div>
  );
};

export default InstructorView;
