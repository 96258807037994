import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { get } from 'superagent';

import ShareableTests from "./shareable-tests";
import { Test } from "../types";

function InstructorTestList() {
  const [tests, setTests] = useState<Array<Test>>([]);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [error, setError] = useState<string|null>(null);

  const getTests = async () => {
    // Validate LTI
    await get(`${process.env.REACT_APP_API_URL}/api/test/`)
    .withCredentials()
    .then((res: any) => {
      setTests(res.body)
      return true;
    })
    .catch((err: any) => {
      if (err.response) {
        setError(err.response.body.message)
      }
    });
    setIsReady(true);
  }

  useEffect(() => {
    getTests();
  }, []);

  if (isReady) {

    return (
      <div className="row">
        <div className="test-list">

          <h1>Map Quizzes</h1>
          <p>Select a quiz below to view.</p>

          {tests.length > 0 ? tests.map((test, i) => {
            return (
              <div className="card" key={i}>
                <div className="card-body">
                  <h5 className="card-title">{test.name}</h5>
                  <p className="card-text">{test.description}</p>
                  <Link key={i} to={`/test/${test.id}`}>
                    <button className="btn btn-primary">
                      View
                    </button>
                  </Link>
                </div>
              </div>
            )
          }) : "You don't have any tests yet."}

        </div>

      {tests.length < 1 ? 
        <ShareableTests getTests={getTests}/>
        : null
      }
      
      </div>
    );
  }

  else {
    return null;
  }
};

export default InstructorTestList;
