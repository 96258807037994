import { useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom";
import { get } from 'superagent';

import StudentTestList from "./test-list";
import TestPage from "./test-page";
import { Test } from "../types";

function StudentView() {

  const [tests, setTests] = useState<Array<Test>>([]);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [error, setError] = useState<string|null>(null);

  const getTests = async () => {
    // Validate LTI
    await get(`${process.env.REACT_APP_API_URL}/api/test/student`)
    .withCredentials()
    .then((res: any) => {
      setTests(res.body)
      return true;
    })
    .catch((err: any) => {
      if (err.response) {
        setError(err.response.body.message)
      }
    });
    setIsReady(true);
  }

  useEffect(() => {
    getTests();
  }, []);

  return (
    <div>

      <Routes>
        <Route path="/test/:testId" element={
          <TestPage getTests={getTests} tests={tests} isReady={isReady} />
        } />
        <Route path="/" element={<StudentTestList tests={tests} isReady={isReady}/>} />
      </Routes>
      
    </div>
  );
};

export default StudentView;
