import { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { get } from 'superagent';

import MapPreview from "./mapPreview";
import Results from "./results";
import { Test } from "../types";

function TestPage() {

  let { testId }: any = useParams();

  // const [tests, setTests] = useState<Array<any>>([]);
  const [test, setTest] = useState<Test|null>(null);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [error, setError] = useState<string|null>(null);

  const getTest = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/test/${testId}`)
    .withCredentials()
    .then((res: any) => {
      setTest(res.body)
      return true;
    })
    .catch((err: any) => {
      if (err.response) {
        setError(err.response.body.message)
      }
    });
    setIsReady(true);
  }

  useEffect(() => {
    getTest();
  }, []);

  if (isReady) {
    if (test) {
      return (
        <div>
          <div className="back-btn"><Link to="/"><i className="bi bi-arrow-left"/> Back to all tests</Link></div>
          <h1>{test.name}</h1>
          <MapPreview mapCoordinates={test.mapCoordinates}/>
          <Results locations={test.locations}/>
        </div>
      );
    }
    else {
      if (error) {
        return <div>{error}</div>;
      }
      else {
        return <div>Test not found</div>;
      }
    }
  }
  
  else {
    return null;
  }

};

export default TestPage;
