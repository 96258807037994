import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { get, post } from 'superagent';
import { Modal, Button, Toast } from 'react-bootstrap';

interface SharableTestProps {
  getTests: () => void
}

function ShareableTests({ getTests }: SharableTestProps) {

  const [tests, setTests] = useState<Array<any>>([]);
  const [error, setError] = useState<string|null>(null);
  const [showShareableTests, setShowShareableTests] = useState<boolean>(false);

  const getShareableTests = async () => {
    // Validate LTI
    await get(`${process.env.REACT_APP_API_URL}/api/test/shareable`)
    .withCredentials()
    .then((res: any) => {
      setTests(res.body)
    })
    .catch((err: any) => {
      if (err.response) {
        setError(err.response.body.message)
      }
    });
  }

  const handleImport = async (testId: number) => {

    await post(`${process.env.REACT_APP_API_URL}/api/test/copy/${testId}`)
    .withCredentials()
    .then((res: any) => {
      getTests();
    })
    .catch((err: any) => {
      console.log(err);
      if (err.response) {
        setError(err.response.body.message)
      }
    });

    setShowShareableTests(false)
  }

  useEffect(() => {
    getShareableTests();
  }, []);

  return (
    <div>

      <br/>
      <button className="btn btn-primary" onClick={() => setShowShareableTests(true)}>Import Test</button>

      <Modal width="300px" show={showShareableTests} onHide={() => setShowShareableTests(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Import Test</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Select a test below to import it into your course.</p>
          {tests.length > 0 ? 
            <div className="import-test-list">
              {tests.map(test => {
                return (
                  <div className="card" key={test.id}>
                    <div className="card-body">
                      <h5 className="card-title">{test.name}</h5>
                      <p className="card-text">{test.description}</p>
                      <button 
                        className="btn btn-primary" 
                        onClick={() => handleImport(test.id)}>
                        Import
                      </button>
                    </div>
                  </div>
                );
              })}
            </div> : 
            <p>There are currently no tests available to import.</p>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowShareableTests(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default ShareableTests;
