import { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { post } from "superagent";

import StudentView from "./student";
import InstructorView from "./instructor";

const App = () => {

  const [role, setRole] = useState<string|undefined>(undefined);
  const [isReady, setIsReady] = useState<boolean>(false);

  const validateUser = async () => {
    // Validate LTI
    await post(`${process.env.REACT_APP_API_URL}/validate-user/`)
    .withCredentials()
    .then((res: any) => {
      setRole(res.body.role)
      return true;
    })
    .catch((err: any) => {
      console.log(err);
      // Handle error
    });
    setIsReady(true);
  }

  useEffect(() => {
    validateUser();
  }, []);

  const hasRole = role === 'student' || role === 'instructor';

  if (isReady) {
    return (
      <div className="App">
        {hasRole && role === 'student' ? <StudentView/> : null}
        {hasRole && role === 'instructor' ? <InstructorView/> : null}
        {!hasRole ? "Unauthorized" : null}
      </div>
    );
  }
  
  else return null;

}

export default App;
