import { useState } from 'react';
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Test } from "../types";
import { getScore } from "../helpers";

interface StudentTestListProps {
  tests: Array<Test>,
  isReady: boolean
}

function StudentTestList({ tests, isReady }: StudentTestListProps) {

  const [selectedTest, setSelectedTest] = useState<Test|undefined>(undefined);

  const ScoreDisplay = () => {
    if (selectedTest) { 
      const result = selectedTest.results[0];
      if (result.isReleased) {
        let { 
          numRequiredQuestions, 
          numRequiredAnswers, 
          numCorrect 
        } = getScore(selectedTest.locations, result.answers);
        return (
          <div>
            <h5>Results for {selectedTest.name}</h5>
            <b>Score: {selectedTest.results[0].score}</b>
            <br/>
            <b>Instructor feedback:</b> {selectedTest.results[0].feedback}
          </div>
        );
      }
      else {
        return <p>Your instructor hasn't released scores for this test yet.</p>;
      }
    }
    return null;
  }

  if (isReady) {
    return (
      <div className="test-list">
        <h2>Your quizzes:</h2>
        <br/>
        
        {tests.length > 0 ? tests.map((test, i) => {
          if (test.results.length === 0) {
            return (
              <div className="card" key={i}>
                <div className="card-body">
                  <h5 className="card-title">{test.name}</h5>
                  <p className="card-text">{test.description}</p>
                  <Link to={`/test/${test.id}`}>
                    <button className="btn btn-primary">
                      Start Test
                    </button>
                  </Link>
                </div>
              </div>
            )
          }

          else {
            return (
              <div className="card" key={i}>
                <div className="card-body">
                  <h5 className="card-title">{test.name}</h5>
                  <p className="card-text">{test.description}</p>
                  <b className="text-success">Submitted <i className="bi bi-check-circle-fill"/></b>
                  <br/>
                  {test.results[0].isReleased ? 
                    <b className="text-primary" style={{cursor: "pointer"}} onClick={() => {
                      setSelectedTest(test);
                    }}>View your score</b> : 
                    <b className="text-muted" style={{cursor: "pointer"}} onClick={() => {
                      setSelectedTest(test);
                    }}>
                      Awaiting score <i className="bi bi-hourglass-split"></i>
                    </b>
                  }
                </div>
              </div>
            )
          }

        }) : "You don't have any tests yet."}

        <Modal show={selectedTest !== undefined} onHide={() => setSelectedTest(undefined)}>
          <Modal.Header closeButton>
            <Modal.Title>Your Results</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ScoreDisplay/>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setSelectedTest(undefined)}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }
  
  else {
    return null;
  }
};

export default StudentTestList;
